import React, { useEffect, useState, useRef } from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "gatsby";
import navbarStyles from "./Navbar.module.css"
import BeckLogoSvg from "../../../images/beck-3d-logo-svg.svg"

const VERTICAL_LINE_SPACE = '0px'

function handleNavScroll(threshold) {
    const scrollTop = document.documentElement.scrollTop;
    const navbar = document.getElementById("navbar");
    const logo = document.getElementById("navLogo");

    if (scrollTop > (threshold ? threshold : 1)) {
        navbar.classList.add(navbarStyles.solidNavbar);
        logo.classList.add(navbarStyles.solidNavbarLogo);
    }
    else {
        navbar.classList.remove(navbarStyles.solidNavbar);
        logo.classList.remove(navbarStyles.solidNavbarLogo);
    }
}

function isMainPage () {
    const page = new URL(window.location.href).pathname === "/" ? true : false;

    return page
}

const BCPNavbar = ({ opacityThreshold }) => {
    const [isIndexPage, setIsIndexPage] = useState(false);
    let navbarToggle = useRef();
    let navbarMenu = useRef();

    function handleScrollEvent (e) { 
        handleNavScroll(opacityThreshold);
        closeNavMenu();
    }

    function closeNavMenu() {
        if(navbarMenu.current?.classList && [...navbarMenu.current.classList].includes("show")) {
            navbarToggle.current.click();
        }
    }

    useEffect(() => {
        // Check if the current page is the main page and set related state accordingly
        setIsIndexPage(isMainPage());

        window.addEventListener("scroll", handleScrollEvent, { passive: true });
        document.addEventListener("click", closeNavMenu, { passive: true });
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.removeEventListener("scroll", handleScrollEvent, { passive: true });
            document.addEventListener("click", closeNavMenu, { passive: true });
        }
    }, [])
    
    return (
        <header>
            <Navbar id="navbar" expand="lg" fixed="top" className={navbarStyles.navbar}>
                <Navbar.Brand as={"a"} href="/" className={`${navbarStyles.navbarBrand}`}>
                    <img
                        id="navLogo"
                        src={BeckLogoSvg}
                        width="50"
                        height="50"
                        className={`d-inline-block align-middle ${navbarStyles.navbarLogo}`}
                        alt="Beck College Prep Logo"
                    />
                    <div className={`${navbarStyles.brandTextContainer}`}>
                        <span className={`${navbarStyles.brandTextHeader}`}>Beck College Prep</span>
                        <span className={`${navbarStyles.brandTextSubheader}`}>We Motivate You</span>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle ref={navbarToggle} aria-controls="basic-navbar-nav" />
                <Navbar.Collapse ref={navbarMenu} id="basic-navbar-nav">
                    <Nav className={`ml-auto text-white ${navbarStyles.navLinks}`}>
                        <Nav.Link as={"a"} href="/"><span className={navbarStyles.linkText}>Home</span></Nav.Link>
                        <Nav.Link as={"a"} href="/#subject-tutoring"><span className={navbarStyles.linkText}>Tutoring</span></Nav.Link>
                        <Nav.Link as={"a"} href="/#sat-act"><span className={navbarStyles.linkText}>SAT <span style={{ marginLeft: VERTICAL_LINE_SPACE, marginRight: VERTICAL_LINE_SPACE }}>|</span> ACT <span style={{ marginLeft: VERTICAL_LINE_SPACE, marginRight: VERTICAL_LINE_SPACE }}>|</span> Test Prep</span></Nav.Link>
                        <Nav.Link as={"a"} href="/#academic-college-advising"><span className={navbarStyles.linkText}>College Planning</span></Nav.Link>
                        <Nav.Link as={Link} to="/contact"><span className={navbarStyles.linkText}>Contact <span style={{ marginLeft: VERTICAL_LINE_SPACE, marginRight: VERTICAL_LINE_SPACE }}>|</span> (305) 562-3814</span></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    )
}

export default BCPNavbar