import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";
import { FaInstagram, FaYoutube, FaFacebookF, FaPhone, FaLinkedinIn } from "react-icons/fa";
import { MdEmail, MdLocationOn } from "react-icons/md";

import styles from "./Footer.module.css";

const VERTICAL = 768;

const Footer = props => {
    const [vertical, setVertical] = useState(false)

	useEffect(() => {
        // Initialize display mode (vertical)
		setDisplayVertical()
		window.addEventListener('resize', setDisplayVertical, { passive: true })

        // This is only used when the components is unmounted
        return function cleanup() {
			window.removeEventListener('resize', setDisplayVertical, { passive: true })
		}
    }, [])
	
	function setDisplayVertical(event) {
        if (window.innerWidth <= VERTICAL) {
            setVertical(true)
        } else {
            setVertical(false)
        }
    }
  
    return (
        <footer className={styles.footer}>
            <Container
                fluid
                className={`d-flex flex-column justify-content-end ${styles.container} bcp page`}
                style={{ fontSize: "12px" }}
            >
                <Row className={styles.containerRow}>
                    <Col sm="auto" md={3} lg={4}>
                        <nav>
                        <div className={styles.linkHeader}>Quick Links</div>
                        <ul className={styles.footerLinkList}>
                            <li>
                            <Link to="/#subject-tutoring">Tutoring</Link>
                            </li>
                            <li>
                            <Link to="/#sat-act">Test Prep</Link>
                            </li>
                            <li>
                            <Link to="/#academic-college-advising">College Planning</Link>
                            </li>
                            {/* <li>
                            <Link to="/aboutus">About Us</Link>
                            </li> */}
                            <li>
                            <Link to="/contact">Contact</Link>
                            </li>
                        </ul>
                        </nav>
                    </Col>
                    <Col sm="auto" md={4}>
                        <div className={styles.linkHeader}>Keep In Touch</div>
                        <ul className={`d-flex ${styles.socialContainer}`} >
                        <li className="mr-2">
                            <a
                            href="https://www.youtube.com/channel/UC9aW2HZV7CUQlqheFCcKelg"
                            className={styles.footerSocialLink}
                            >
                            <FaYoutube size={20} />
                            </a>
                        </li>
                        <li className="mx-2">
                            <a
                            href="https://www.facebook.com/BeckCollegePrep/"
                            className={styles.footerSocialLink}
                            >
                            <FaFacebookF size={20} />
                            </a>
                        </li>
                        <li className="mx-2">
                            <a
                            href="https://www.instagram.com/beckcollegeprep/"
                            className={styles.footerSocialLink}
                            >
                            <FaInstagram size={24} />
                            </a>
                        </li>
                        <li className="ml-2">
                            <a
                            href="https://www.linkedin.com/company/beck-college-prep/"
                            className={styles.footerSocialLink}
                            >
                            <FaLinkedinIn size={24} />
                            </a>
                        </li>
                        
                        </ul>
                    </Col>
                    <Col xs="auto" md={5} lg={4}>
                        <nav>
                        <div className={styles.linkHeader}>Contact Us</div>
                        <ul className={styles.footerLinkList}>
                            <div>
                            <span>
                                <FaPhone size={15} title="Phone" />
                                &emsp;
                                {vertical ? (
                                    <a href="tel:+1-305-562-3814">
                                        305-562-3814
                                    </a>
                                ):(
                                    <Link to='/contact'>
                                        305-562-3814
                                    </Link>
                                )}
                                
                            </span>
                            </div>
                            
                            <div>
                            <span>
                                <MdEmail size={15} title="Email" />
                                &emsp;
                                <Link to='/contact'>
                                    a.beck@beckcollegeprep.com
                                </Link>
                            </span>
                            </div>
                        
                            <div>
                            <span>
                                <MdLocationOn size={15} title="Location" />
                                &emsp;
                                <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://tinyurl.com/yckjrdes"
                                >
                                1550 Madruga Avenue, Suite 230, Coral Gables, Fl
                                </a>
                            </span>
                            </div>
                            
                        </ul>
                        </nav>
                    </Col>
                </Row>
                <Row className={styles.containerRow}>
                    <div className="dropdown-divider"></div>
                    <hr />
                    <Col className="text-center justify-content-end">
                        <div style={{ position: "absolute", bottom: 0, left: 0 }}>
                        Copyright &#169; 2020 Beck College Prep. All Rights Reserved.
                        </div>
                    </Col>
                    <Col className="text-center">
                        <div style={{ position: "absolute", bottom: 0, right: 0}}>
                        {/* <Link to="/sitemap">Sitemap</Link>
                        &ensp;|&ensp; */}
                        <Link to="/terms-and-conditions">Terms And Conditions</Link>
                        &ensp;|&ensp;
                        <Link to="/contact">Contact Us</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
